import ThirdParty, { Google, Github, Apple, Twitter, Facebook } from "supertokens-auth-react/recipe/thirdparty";
import { ThirdPartyPreBuiltUI } from "supertokens-auth-react/recipe/thirdparty/prebuiltui";
import EmailPassword from "supertokens-auth-react/recipe/emailpassword";
import { EmailPasswordPreBuiltUI } from "supertokens-auth-react/recipe/emailpassword/prebuiltui";
import Session from "supertokens-auth-react/recipe/session";
import Cookies from "js-cookie";



export function getApiDomain() {
    const apiPort = process.env.REACT_APP_API_PORT || 3001;
    const apiUrl = process.env.REACT_APP_API_URL || `http://localhost:${apiPort}`;

    return apiUrl;
}

export function getWebsiteDomain() {
    const websitePort = process.env.REACT_APP_WEBSITE_PORT || 3000;
    const websiteUrl = process.env.REACT_APP_WEBSITE_URL || `http://localhost:${websitePort}`;
    return websiteUrl;
}

export const SuperTokensConfig = {
    appInfo: {
        appName: "SuperTokens Demo App",
        apiDomain: getApiDomain(),
        websiteDomain: getWebsiteDomain(),
    },
    style: `
    [data-supertokens="superTokensBranding"] { 
        display: none !important;
    }
    `,

    // recipeList contains all the modules that you want to
    // use from SuperTokens. See the full list here: https://supertokens.com/docs/guides
    recipeList: [
        EmailPassword.init({
            signInAndUpFeature: {
                signUpForm: {
                    formFields: [
                        {
                            id: "password",
                            label: "Password",
                            // getDefaultValue: () => "john.doe@gmail.com"
                        },
                        {
                            id: "first_name",
                            label: "first name",
                            // getDefaultValue: () => "John",
                        },
                        {
                            id: "last_name",
                            label: "last name",
                            // getDefaultValue: () => "Doe",
                        },
                        {
                            id: "email",
                            label: "Your Email",
                            // getDefaultValue: () => "john.doe@gmail.com"
                        },
                        {
                            id: "company_name",
                            label: "company name",
                            // getDefaultValue: () => "Apple",
                        },
                    ]
                },

            },
            onHandleEvent: function (context) {
                if (context.action === "SUCCESS") {
                    //redirect to login via id
                    // window.location.href='http://127.0.0.1/yeti/index.php?module=Users&view=LoginViaSuperToken&token='+context.user.id;
                }
            }
        }),
        ThirdParty.init({
            signInAndUpFeature: {
                providers: [Google.init(), Facebook.init()],
            },
        }),
        Session.init(),
    ],
};

export const recipeDetails = {
    docsLink: "https://supertokens.com/docs/thirdpartyemailpassword/introduction",
};



export const PreBuiltUIList = [ThirdPartyPreBuiltUI, EmailPasswordPreBuiltUI];

export const ComponentWrapper = (props: { children: JSX.Element }): JSX.Element => {
    return props.children;
};

