import "./App.css";
import SuperTokens, { SuperTokensWrapper } from "supertokens-auth-react";
import { getSuperTokensRoutesForReactRouterDom } from "supertokens-auth-react/ui";
import { SessionAuth } from "supertokens-auth-react/recipe/session";
import { Routes, BrowserRouter as Router, Route } from "react-router-dom";
import Home from "./Home";
import { PreBuiltUIList, SuperTokensConfig, ComponentWrapper } from "./config";
import { EmailPasswordComponentsOverrideProvider } from "supertokens-auth-react/recipe/emailpassword";

SuperTokens.init(SuperTokensConfig);

function App() {
    return (
        <SuperTokensWrapper>
            <EmailPasswordComponentsOverrideProvider
                components={{
                    EmailPasswordSignUpForm_Override: ({ DefaultComponent, ...props }) => {
 
                        return (
                            <DefaultComponent
                                {...props}
                                formFields={[
                                    props.formFields.find(({ id }) => id === 'first_name')!,
                                    props.formFields.find(({ id }) => id === 'email')!,
                                    props.formFields.find(({ id }) => id === 'last_name')!,
                                    props.formFields.find(({ id }) => id === 'password')!, 
                                    props.formFields.find(({ id }) => id === 'company_name')!,
                                ]}

                                
                            />
                        );
                    },
                }}>
                {/* Rest of the JSX */}
            </EmailPasswordComponentsOverrideProvider>
            <ComponentWrapper>
                <div className="App app-container">
                    <Router>
                        <div className="fill">
                            <Routes>
                                {/* This shows the login UI on "/auth" route */}
                                {getSuperTokensRoutesForReactRouterDom(require("react-router-dom"), PreBuiltUIList)}

                                <Route
                                    path="/"
                                    element={ 
                                        <SessionAuth>
                                            <Home />
                                        </SessionAuth>
                                    }
                                />
                            </Routes>
                        </div>
                    </Router>
                </div>
            </ComponentWrapper>
        </SuperTokensWrapper>
    );
}

export default App;
