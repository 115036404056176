import { useSessionContext } from "supertokens-auth-react/recipe/session";

export default function RedirectBtn(props: { to: string }) {
    const sessionContext = useSessionContext();
    const callRedirect =() => {
        if (sessionContext.loading === true) {
            return null;
        }
        let url = '';
        if (props.to === 'crm') {


            url =  process.env.REACT_APP_CRM_BASEURL+'?module=Users&view=LoginViaSuperToken&token=' + sessionContext.userId;
        }
        else if (props.to === 'saqan') {
            url = 'https://www.saqan.com'
        }
        window.location.href = url;
    }

    return (
        <>
            <div onClick={()=>callRedirect()} className="sessionButton redirect">
                Redirect to crm
            </div>

            <div onClick={()=>callRedirect()} className="sessionButton redirect">
                Redirect to agentSouq
            </div>

        </>
    );
}
